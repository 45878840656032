<template>
    <form class="cform box" :class="{'box--focused': this.form.hasChanges()}">
        <loading-overlay v-if="form.isUpdating"></loading-overlay>
        <h2>{{form.title}}</h2>
        <div class="cform__group" v-for="(item, key) in form.items" :key="key">
            <label class="cform__label" :for="key">{{item.label}}</label>
            <div class="cform__input-group">
                <input type="text" :id="key" v-model="item.value">
            </div>
            <span class="cform__feedback"
                  v-if="form.errors.has(key)" v-text="form.errors.get(key)"></span>
        </div>
        <div class="box__buttons" v-if="form.hasChanges()">
            <input-button :text="buttons.submit" no-margin="true" @click="onSubmit"></input-button>
            <input-button :text="buttons.reset" no-margin="true" cancel="true"
                          @click="onReset"></input-button>
        </div>
    </form>
</template>

<script>
    import Form from '../../../services/form';
    import InputButton from '../../../components/form-elements/input-button.vue';
    import LoadingOverlay from '../../../components/loading-overlay.vue';
    import { _T } from '../../../globals';
    import Bus from '../../../bus';

    export default {
        props: ['tenantBranding'],
        components: { InputButton, LoadingOverlay },
        data() {
            return {
                isUploading: false,
                form: new Form(_T('General information'), {
                    site_name: {
                        label: _T('site name'),
                        value: this.tenantBranding.site_name
                    },
                    claim: {
                        label: _T('claim'),
                        value: this.tenantBranding.claim
                    }
                }),
                buttons: {
                    submit: _T('submit'),
                    reset: _T('Cancel')
                },
                messages: {
                    saved: _T('saved')
                }
            };
        },
        methods: {
            onSubmit() {
                const data = this.form.values();

                this.form.isUpdating = true;
                this.form.errors.clearAll();

                this.tenantBranding.save(data)
                    .then(() => {
                        this.form.save();
                        Bus.$emit('default.message', this.messages.saved);
                        this.form.isUpdating = false;
                    })
                    .catch((error) => {
                        this.form.errors.record(error.response.data);
                        this.form.isUpdating = false;
                    });
            },
            onReset() {
                this.form.reset();
            }
        }
    };
</script>
