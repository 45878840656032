<style lang="scss">
    .header-logo {
        float: left;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            display: block;
        }
    }
</style>

<template>
    <a class="header-logo" href="/">
        <img :src="src">
    </a>
</template>

<script>
    import Bus from '../bus';

    export default {
        props: ['code'],
        data() {
            return {
                src: `${this.code}/images/header_logo.png`
            };
        },
        created() {
            Bus.$on('refresh.logo', this.refreshImage);
        },
        methods: {
            refreshImage() {
                this.src = `${this.src}?refresh=${new Date().getTime()}`;
            }
        }
    };
</script>
