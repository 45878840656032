import axios from 'axios';
import { API } from '../constants/constants';

export default class artificialStreamingReport {
    constructor(description, file, report_date) {
        this.report_date = report_date
        this.description = description;
        this.file = file;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const data = new FormData();

            if (!this.description || !this.file || !this.report_date) {
                this.clean();
                reject({ response:{data:['Fields cannot be empty']}});

            } else {
                const endpoint = this.getEndpoint();

                data.append('report_year_month', this.report_date);
                data.append('description', this.description);
                data.append('file', this.file);

                axios({
                    data,
                    method: 'POST',
                    url: endpoint
                }).then(() => {
                    this.clean();
                    resolve();
                }).catch((error) => {
                    this.clean();
                    reject(error);
                });
            }
        });
    }

    getEndpoint() {
        const pathname = document.location.pathname;
        const re = /clientartificialstreamingreports/i;

        if (pathname.match(re)) {
            return API.CLIENT_ARTIFICIAL_STREAMING_REPORT;
        } else {
            return API.PLATFORM_ARTIFICIAL_STREAMING_REPORT;
        }
    }

    clean() {
        this.description = '';
        this.file = null;
    }
}
