<template>
    <div class="grid">
        <div class="col-1-2">
            <tenant-branding-info :tenantBranding="tenantBranding"></tenant-branding-info>
            <tenant-branding-images :tenantBranding="tenantBranding"></tenant-branding-images>
        </div>
        <div class="col-1-2">
            <tenant-branding-colors :tenantBranding="tenantBranding"></tenant-branding-colors>
        </div>
    </div>
</template>

<script>
    import TenantBranding from '../../models/tenant-branding';
    import TenantBrandingColors from './components/tenant-branding-colors.vue';
    import TenantBrandingImages from './components/tenant-branding-images.vue';
    import TenantBrandingInfo from './components/tenant-branding-info.vue';

    export default {
        props: ['branding'],
        components: { TenantBrandingColors, TenantBrandingImages, TenantBrandingInfo },
        data() {
            return {
                tenantBranding: new TenantBranding(this.branding)
            };
        }
    };
</script>
