import Vue from 'vue';
import artificialStreamingReportUpload from './components/artificial-streaming-report-upload.vue';

function dateToMonthPickerFormat(date) {
    const dateSplitted = date.split('-');

    return `${dateSplitted[1]}/${dateSplitted[0]}`;
}

export default class Upload {
    constructor(options) {
        return this.constructor.load(options);
    }

    static load(options) {
        const data  = options;
        data.date = data.type === 'monthpicker' ? dateToMonthPickerFormat(data.date) : data.date;

        return new Vue({
            el: '#artificial-streaming-report-upload',
            data: {
                date: data.date,
                type: data.type
            },
            components: { artificialStreamingReportUpload },
            methods: {
                uploaded() {
                    this.onUploaded();
                },
                onUploaded() {}
            }
        });
    }
}
