<style lang="scss">
    .metadata-box {
        border: 1px solid #C3C4BE;
        border-radius: 3px;
        overflow: hidden;
    }

    .metadata-box__head {
        display: flex;
        align-items: center;
        padding: 10px;
        background: lighten(#C3C4BE, 15%);

        .metadata-box__title {
            flex: 1;
            display: inline;
            margin: 0;
            padding-right: 20px;
            font-size: 16px;
            font-weight: 400;
        }

        .metadata-box__subtitle {
            display: inline;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            color: darken(#C3C4BE, 15%);
        }
    }

    .metadata-box__body {
        display: flex;
        border-top: 1px solid #C3C4BE;
        background: #FFFFFF;
    }

    .metadata-box__content {
        display: inline-block;
        border-right: 1px solid #C3C4BE;
        width: 50%;
        padding: 10px;

        .list__item-key {
            width: 40%;
        }
    }

    .metadata-box__sidebar {
        flex: 1;
        display: inline-block;
        padding: 10px;
        background: lighten(#C3C4BE, 20%);
    }
</style>

<template>
    <div class="metadata-box">
        <div class="metadata-box__head">
            <h2 class="metadata-box__title">{{metadata.original.title}}</h2>
            <h3 class="metadata-box__subtitle">{{metadata.original.date}}</h3>
        </div>
        <div class="metadata-box__body">
            <div class="metadata-box__content">
                <template>
                    <h3 class="title">{{titles.userInfo}}</h3>
                    <list>
                        <list-item v-for="(value, key) in userdata" :key="key">
                            <template slot="key">{{translate(key)}}</template>
                            <template slot="value">
                                <a :href="flagUrl" target="_blank"> {{value | toString}}</a>
                            </template>

                        </list-item>
                    </list>
                </template>
                <template v-if="hasInfo">
                    <h3 class="title">{{titles.basicInfo}}</h3>
                    <list>
                        <list-item v-for="(value, key) in metadata.info" :key="key">
                            <template slot="key">{{key}}</template>
                            <template slot="value">{{value | toString}}</template>
                        </list-item>
                    </list>
                </template>
                <template v-if="hasArtists">
                    <h3 class="title">{{titles.artists}}</h3>
                    <list>
                        <list-item v-for="(value, key) in metadata.artists" :key="key">
                            <template slot="key">{{translate(key)}}</template>
                            <template slot="value">
                                <span v-for="(name, index) in value" :key="index">
                                    <strong>{{name}}</strong>
                                    <span v-if="index + 1 < value.length"> | </span>
                                </span>
                            </template>
                        </list-item>
                    </list>
                </template>
            </div>
            <div class="metadata-box__sidebar">
                <assets-box :release="metadata"
                            :assets="metadata.assets" v-if="hasAssets"></assets-box>
            </div>
        </div>
    </div>
</template>

<script>
    import ArtistBox from './artist-box.vue';
    import AssetsBox from './assets-box.vue';
    import CallOut from './call-out.vue';
    import List from './list.vue';
    import ListItem from './list-item.vue';
    import { _T } from '../globals';

    export default {
        props: ['metadata', 'userdata', 'id'],
        components: {
            // eslint-disable-next-line vue/no-unused-components
            ArtistBox,
            AssetsBox,
            // eslint-disable-next-line vue/no-unused-components
            CallOut,
            List,
            ListItem
        },
        data() {
            return {
                titles: {
                    basicInfo: _T('metadata_version_basic_info'),
                    userInfo: _T('user_information'),
                    artists: _T('metadata_version_artists')
                },
                flagUrl: '/admin/users/'+this.id+'/flags'
            };
        },
        methods: {
            translate(string) {
                return _T(string);
            }
        },
        computed: {
            hasInfo() {
                return !!Object.keys(this.metadata.info).length;
            },
            hasArtists() {
                return !!Object.keys(this.metadata.artists).length;
            },
            hasAssets() {
                return !!Object.keys(this.metadata.assets).length;
            }
        }
    };
</script>
