<style>
    .user-dropdown .dropdown__selected .dropdown__user_icon {
        width: 20px;
        height: 21px;
    }

    .user-dropdown .dropdown__selected .dropdown__user_icon svg {
        fill: none;
        width: 20px;
        height: 21px;
    }
</style>

<template>
    <dropdown :selected="''" :class="'user-dropdown'" :options="options" @optionSelected="doAction">
        <span class="dropdown__user_icon" slot="icon"><user-icon viewBox="0 0 13 14"></user-icon></span>
    </dropdown>
</template>

<script>
    import Dropdown from './dropdown.vue';
    import UserIcon from '../../svg/user-white.svg';
    import User from '../models/user';
    import { URLS, USER_ACTIONS } from '../constants/constants';
    import { _T, PromptLine, loginAsUser } from '../globals';

    export default {
        props: ['selected', 'options'],
        components: { Dropdown, UserIcon },
        methods: {
            doAction(option) {
                switch (option.action) {
                    case USER_ACTIONS.GO_TO_PROFILE:
                        window.location.href = URLS.USER_PROFILE;
                        break;
                    case USER_ACTIONS.CHANGE_USER:
                        PromptLine(_T('enter user email you want to login as'), (_login) => {
                            if (_login.length) {
                                loginAsUser(_login);
                            }
                        });
                        break;
                    case USER_ACTIONS.LEAVE_USER:
                        document.body.style.cursor = 'wait';

                        User.leave()
                            .then(() => {
                                window.location.href = '/';
                                document.body.style.cursor = 'default';
                            }).catch(() => {
                                document.body.style.cursor = 'default';
                            });
                        break;
                    case USER_ACTIONS.LOGOUT:
                        window.location.href = URLS.LOGOUT;
                        break;
                    default:
                        break;
                }
            }
        }
    };
</script>
